.worker-variant-listing {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
}

.worker-variant-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
  }
}
