.__button {
  text-align: center;

  .align {
    &.right {
      text-align: right;
    }

    &.left {
      text-align: left;
    }

    &.center {
      text-align: center;
    }

    &.full {
      button {
        width: 100%;
      }
    }
  }
}

.align-buttons {
  display: flex !important;
  align-items: center;

  .ui.buttons {
    .ui.icon.button {
      padding: 4px;
      margin-left: 4px;
      border-radius: 1px;
      color: #826a6a !important;
    }

    .ui.active.basic.icon.button {
      border: 1px solid #2996da;
      background: transparent !important;
    }

    &:hover {
      background-color: #edf1f2 !important;
    }
  }
}

.iskra-button {
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.iskra-button-primary {
  border: 1px solid $iskra-primary-color;
  background-color: $iskra-primary-color;
  color: white;

  &:hover {
    background-color: transparent;
    color: $iskra-primary-color;
  }
}

.iskra-button-transparent {
  border: 1px solid $iskra-primary-color;
  background-color: transparent;
  color: $iskra-primary-color;

  &:hover {
    background-color: $iskra-primary-color;
    color: white;
  }
}

.iskra-button-secondary {
  border: 1px solid $iskra-secondary-color;
  background-color: $iskra-secondary-color;
  color: black;

  &:hover {
    background-color: $iskra-secondary-color-hover;
  }
}

.iskra-button-small {
  padding: 10px 15px 10px 15px;
  font-size: 14px;
  letter-spacing: -0.2px;
}

.iskra-button-large {
  padding: 15px 20px 15px 20px;
  font-size: 18px;
  letter-spacing: 0px;
}
