$border-color: rgba(120, 192, 215, 0.75);

.block-editor-__iskra-group {
  [data-rbd-draggable-context-id] {
    margin-bottom: 1rem;
  }

  .block-add-button {
    display: none !important;
  }

  .block.__iskra-group.selected::before,
  .block.__iskra-group:hover::before {
    border-style: dashed;
  }

  fieldset {
    border: none;

    legend {
      position: absolute;
      z-index: 3;
      top: -1.3em;
      left: 0;
      width: fit-content;
      padding: 0 1rem;
      margin-right: auto;
      margin-left: auto;
      background-color: #fff;
      color: $border-color;
      cursor: pointer;
      text-align: center;
    }
  }

  .section-block {
    padding-top: 1rem;
    padding-bottom: 0.1rem;
    margin: 0;
    outline: none;
  }

  .counter {
    display: grid;
    align-items: center;
    font-size: 85%;
    grid-gap: 0.5em;
    grid-template-columns: 98% auto;
    text-align: end;

    &.info {
      color: #ccc;
    }

    &.danger {
      color: crimson;
    }

    &.warning {
      color: darkorange;
    }
  }

  .blocks-form {
    margin-top: 0.5rem;
  }

  .blocks-chooser {
    right: 0;
    left: auto;
    margin-top: 3rem;
  }

  .block-toolbar {
    position: absolute;
    z-index: 3;
    right: -9px;
    display: flex;
    border: none;
    border: 1px solid $border-color;
    border-bottom: 1px solid #fff;
    margin-top: -45px;
    background-color: #fff;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    .ui.basic.button {
      padding: 8px 5px;
    }

    .ui.basic.button:hover {
      background: transparent !important;
    }
  }
}
