// Colors
$iskra-primary-color: #000;
$iskra-secondary-color: #fcb713;
$iskra-secondary-color-hover: #ffce59;

$iskra-success-color: #0070f3;
$iskra-error-color: #ee0000;
$iskra-color-accent-1: #fafafa;
$iskra-color-accent-2: #eaeaea;
$iskra-color-accent-3: #999;
$iskra-color-accent-4: #888;
$iskra-color-accent-5: #666;
$iskra-color-accent-6: #444;
$iskra-color-accent-7: #333;
$iskra-color-accent-8: #111;
