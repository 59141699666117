@import 'variables';
@import 'spacing-helpers';
@import 'addonsThemeCustomizationsVariables';

main {
  padding-top: 80px;
}

.content-area {
  padding: 0 !important;
  margin: 0 !important;
}

// .ui.segment {
//   margin:0;
//   padding:0;
// }

// override hamburger menu
.hamburger-box {
  width: 32px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 32px;
  height: 2px;
}

.hamburger-inner::before {
  top: -8px;
}

.hamburger-inner::after {
  bottom: -8px;
}

// Own styles

.display-none {
  display: none;
}

.flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.background-iskra-gradient {
  background: linear-gradient(
    180deg,
    rgba(252, 183, 19, 0.12) 0%,
    var(--background, rgb(255, 255, 255)) /* {"name":"background"} */ 100%
  );
}

.background-iskra-gray {
  background: $iskra-color-accent-2;
}

.wrapper-card {
  padding: 24px 24px 24px 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0px 24px 32px 3.2px rgba(0, 0, 0, 0.05);
}

.wrapper-card-gray {
  padding: 24px 24px 24px 24px;
  border: 1px solid $iskra-color-accent-2;
  background-color: $iskra-color-accent-1;
  border-radius: 8px;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.margin-zero {
  margin: 0;
}

.error-color {
  color: $iskra-error-color;
}

.wrapper-padding-without-margin {
  p {
    margin: 0;
  }
}

// Typography
.theme-title-big-text {
  font-size: clamp(2rem, 4vw + 1rem, 4rem); // Max 64px
  line-height: clamp(2.625rem, 4vw + 1rem, 4.625rem); // Max 74px
}

.theme-title-medium-text {
  font-size: clamp(1.75rem, 2vw + 1rem, 2.5rem); // Max 40px
  line-height: clamp(2rem, 2vw + 1rem, 2.75rem); // Max 44px
}

.theme-title-small-text {
  font-size: 1.5rem; // 24px
  line-height: 2rem; // 32px
}

.theme-body1-text {
  font-size: 1.25rem; // 20px
  line-height: 1.5rem; // 24px
}

.theme-body2-text {
  font-size: 1rem; //16px
  line-height: 1.25rem; // 20px
}

.theme-body3-text {
  font-size: 0.875rem; // 14px
  line-height: 1.125rem; // 18px
}

.theme-body3-text-space-letter {
  font-size: 0.875rem; // 14px
  letter-spacing: 0.2rem; // 3px
  line-height: 1.125rem; // 18px
}

.theme-caption-text {
  font-size: 0.75rem; // 12px
  line-height: 0.875rem; // 14px
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-lowercase {
  text-transform: lowercase;
}

.text-font-weight-bold {
  font-weight: bold;
}

.theme-gray-text-color {
  color: $iskra-color-accent-5;
}

.theme-secondary-text-color {
  color: $iskra-secondary-color;
}

.iskra-footer-section {
  padding: 1.5em;
  border-top: 1px solid $iskra-color-accent-2;
  margin: 0 2rem;

  input {
    padding: 1rem;
    border: 0;
    background-color: rgb(250, 250, 250);
  }
}

.iskra-footer-navigation-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 767px) {
    gap: 20px;
    grid-template-columns: 1fr;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  a {
    color: $iskra-color-accent-5;

    &:hover {
      color: #000;
    }
  }
}

.iskra-footer-navigation-section-column {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
}

.iskra-contact-page {
  &-form {
    display: flex;
    max-width: 500px;
    flex-direction: column;
    margin: 0 auto;
    gap: 20px;

    input,
    textarea {
      width: 100%;
      padding: 1rem;
      border: 0;
      background-color: rgb(250, 250, 250);
    }
  }

  &-footer {
    display: grid;
    align-items: center;
    margin-top: 100px;
    grid-template-columns: 6fr 4fr;
  }
}

.mobile-flex-row {
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
  }
}

.iskra-header-section {
  position: fixed;
  z-index: 2;
  width: 100%;
  background-color: #fff;

  .iskra-menu-bg {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .iskra-menu {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: space-between;
  }

  .iskra-mobile-menu {
    position: fixed;
    right: 0;
    width: 100%;
    width: 100%;
    padding: 0 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 0;
    background-color: #fff;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.is-open {
      opacity: 1;
    }

    .container-links {
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      padding: 15px;
    }
  }

  .container-links {
    display: flex;
    align-items: center;
    gap: 30px;

    a {
      color: $iskra-color-accent-5;
      font-size: 14px;
      font-weight: 400;

      &:hover,
      &.selected {
        color: $iskra-primary-color;
      }
    }
  }
}

.stretch {
  z-index: 1;
}

@import 'addonsThemeCustomizationsMain';
