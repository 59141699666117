.iskra-card-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  padding: 24px;
  border: 1px solid $iskra-color-accent-2;
  border-radius: 5px;

  &__with-shadow {
    box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.12);
  }
}

.iskra-generic-card-wrapper {
  border-radius: 5px;

  &.flex {
    padding: 24px;
    gap: 16px;
  }

  &__with-shadow {
    margin-bottom: 40px;
    box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.12);
  }
}

.iskra-two-columns-card-line {
  display: grid;
  grid-template-columns: 74px auto;
}

.iskra-card-title {
  display: grid;
  align-items: center;
  margin-bottom: 24px;
  color: $iskra-primary-color;
  font-weight: 600;
  gap: 15px;
  grid-template-columns: 50px auto;
  text-align: left;
}

.iskra-card-subtitle {
  color: $iskra-color-accent-5;
  line-height: 24px;

  a {
    color: $iskra-color-accent-5;
  }
}

.with-margin {
  margin-bottom: 24px;
}

.square {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border: 1px solid $iskra-color-accent-2;
  background-color: $iskra-color-accent-1;
  border-radius: 8px;

  &__with-shadow {
    background-color: $iskra-secondary-color;
  }
}
