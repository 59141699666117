.slider-background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-slider {
  display: flex;
  height: 700px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    height: 500px;
    margin: 0 1rem;
  }
}

.content-main-slider {
  display: flex;
  max-width: 800px;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.addon-test-title {
  font-size: clamp(2rem, 4vw + 1rem, 4.625rem);
  font-weight: 700;
  letter-spacing: -2px;
  line-height: clamp(2.625rem, 4vw + 1rem, 4.625rem);
  text-align: center;
}

.addon-test-subtitle {
  color: $iskra-color-accent-5;
  font-size: clamp(1rem, 1vw + 0.75rem, 1.5rem);
  letter-spacing: -0.32px;
  line-height: 1.6;
  text-align: center;
}

.wrapper-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
  gap: 24px;
}
