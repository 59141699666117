$spaceamounts: (
  8,
  16,
  24,
  32,
  40,
  48,
  56,
  64,
  72,
  80,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@mixin generate() {
  @each $space in $spaceamounts {
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}px;
      }

      .p#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space}px;
      }
    }
  }
}

@mixin generate-gap() {
  @each $space in $spaceamounts {
    .gap-#{$space} {
      gap: #{$space}px;
    }
  }
}

@include generate-gap();
@include generate();
