.iskra-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iskra-icon-shape {
  &-circle {
    border-radius: 50%;
  }

  &-rounded {
    border-radius: 8px;
  }
}

.iskra-icon-size {
  &-mini {
    width: 28px;
    height: 28px;
  }

  &-small {
    width: 50px;
    height: 50px;
  }

  &-large {
    width: 75px;
    height: 75px;
  }
}
